import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { makeAuthenticatedApiCall } from "hubl/utils/helpers";


export const fetchFlaggedTickets = createAsyncThunk(
    "tickets/flagged/all",
    async (_, thunkAPI) => {
      try {
        const res = await makeAuthenticatedApiCall(
        "tickets/flagged/all",
          "GET",
          null,
          thunkAPI
        );
  
        const data = await res.json();
        if (res.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        console.error("Failed to fetch flagged tickets", error);
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

  
export const createFlaggedTicket = createAsyncThunk(
    "tickets/flagged/create",
    async (body, thunkAPI) => {
        try {
            const res = await makeAuthenticatedApiCall(
                `tickets/flag/create/`,
                "POST",
                body,
                thunkAPI
            );

            const data = await res.json();
            if (res.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            console.error("Failed to create flagged ticket", error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteFlaggedTicket = createAsyncThunk(
    "tickets/flagged/delete",
    async (ticket_number, thunkAPI) => {
        try {
            const res = await makeAuthenticatedApiCall(
                `tickets/flag/delete/${ticket_number}`,
                "DELETE",
                null,
                thunkAPI
            );

            // const data = await res.json();
            if (res.status === 204) {
                return ticket_number;
            } else {
                return thunkAPI.rejectWithValue("Ticket deletion failed")
            }
        } catch (error) {
            console.error("Failed to create flagged ticket", error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const ticketsSlice = createSlice({
    name: "tickets",
    initialState: {
      flaggedTickets: [],
      flaggedTicketsLoading: false,
    },
    reducers: {
        addFlag: (state, action) => {
        return {
          ...state,
          flaggedTickets: [...state.flaggedTickets, action.payload]
        };
      },
      removeFlag: (state, action) => {
        return {
          ...state,
          flaggedTickets: state.flaggedTickets.filter(ticket => ticket.ticket_number !== action.payload)
        };
      }
    
    },
      
    extraReducers: (builder) => {
      builder
        .addCase(fetchFlaggedTickets.fulfilled, (state, action) => {
          state.flaggedTickets = action.payload;
          state.flaggedTicketsLoading = false;
        })
        .addCase(fetchFlaggedTickets.rejected, (state) => {
          state.flaggedTicketsLoading = false;
        })
        .addCase(fetchFlaggedTickets.pending, (state) => {
          state.flaggedTicketsLoading = true;
        })
    },
  });
  
  export const { addFlag, removeFlag } = ticketsSlice.actions;
  export default ticketsSlice.reducer;
  