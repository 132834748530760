import { createSlice } from "@reduxjs/toolkit";

const sessionTimeoutSlice = createSlice({
    name: 'setSessionTimeout',
    initialState: {
      timeout:20 * 60 * 1000, // 5 minutes
      isTimedOut: false
  },
  reducers: {
    sessionTimeout: (state, action) => {
      state.isTimedOut = action.payload;
    },
  },
});

export const { sessionTimeout } = sessionTimeoutSlice.actions;
export default sessionTimeoutSlice.reducer;
