import { createSlice } from '@reduxjs/toolkit'
import { models } from 'powerbi-client';

export const setReportConfigSlice = createSlice({
  name: 'reportConfig',
  initialState: {
    type: "report",
    embedUrl: null,
    tokenType: models.TokenType.Embed,
    accessToken: '',
    theme: null,
    settings: {
      navContentPaneEnabled: false,
      layoutType: null,
      zoomLevel: 1,
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
      background: models.BackgroundType.Transparent,
    }

  },
  reducers: {
    setReportConfig: (state, action) => {
      Object.assign(state, action.payload);
    },
  }
  })

export const { setReportConfig } = setReportConfigSlice.actions
export default setReportConfigSlice.reducer
