import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { models } from "powerbi-client";
import { setReportConfig } from "slice/reportConfig";
import { makeAuthenticatedApiCall } from "hubl/utils/helpers";

export const register = createAsyncThunk(
  "users/register",
  async ({ first_name, last_name, email, password }, thunkAPI) => {
    const body = JSON.stringify({
      first_name,
      last_name,
      email: email.toLowerCase(),
      password,
    });

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/users/register/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      const data = await res.json();

      if (res.status === 201) {
        toast.success("User Registered", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getUser = createAsyncThunk("users/me", async (_, thunkAPI) => {
  try {
    const res = await makeAuthenticatedApiCall("api/users/me", "GET",
    null, thunkAPI);
    const data = await res.json();
    if (res.status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchSpecificUser = createAsyncThunk(
  "users/specific",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "api/users/specific/",
        "POST",
        body,
        thunkAPI
      )
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.error("Failed to fetch specific user", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);



export const getUserTickets = createAsyncThunk(
  "tickets/c2p/all",
  async ({ organization_id }, thunkAPI) => {
    const body = {
      organization_id,
    };

    try {
      const res = await makeAuthenticatedApiCall(
        "tickets/c2p/all/",
        "POST",
        body, thunkAPI
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        if (data.message) {
          toast.error(data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Unable to find partner ticket", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error("Unable to find partner ticket", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getUserTicketSingle = createAsyncThunk(
  "tickets/c2p/single",
  async ({ organization_id, ticket_number }, thunkAPI) => {
    const body = {
      organization_id,
      ticket_number,
    };
    try {
      const res = await makeAuthenticatedApiCall(
        "tickets/c2p/single/",
        "POST",
        body, thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        if (data.message) {
          toast.error(data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Unable to find partner ticket", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error("Unable to find partner ticket", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getUserSubscriptions = createAsyncThunk(
  "users/subscriptions",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall("api/users/subscriptions", 'GET', null, thunkAPI);
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getUserReportSection = createAsyncThunk(
  "api/users/reportsections",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall("api/users/reportsections", 'GET', null, thunkAPI);
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const logUserLogin = createAsyncThunk(
  "users/login/log",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "api/logs/user/login/", 'POST', null, thunkAPI);
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const login = createAsyncThunk(
  "users/login",
  async ({ email, password, remember_me }, thunkAPI) => {
    const body = JSON.stringify({
      email: email.toLowerCase(),
      password,
    });

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      const data = await res.json();
      if (res.status === 200) {
        if (remember_me) {
          localStorage.setItem("authTokens", JSON.stringify(data));
        } else {
          sessionStorage.setItem("authTokens", JSON.stringify(data));
        }

        toast.success("Succesfully Logged In", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const reset_password = createAsyncThunk(
  "auth/users/reset_password",
  async ({ email }, thunkAPI) => {
    const body = JSON.stringify({
      email: email.toLowerCase(),
    });

    try {
      toast.info("Password reset requested", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/users/reset_password/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });
      if (res.status === 200) {
        toast.success("Password Reset Email sent", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return thunkAPI.fulfillWithValue({
          success: "Password Reset Email sent",
        });
      } else {
        return thunkAPI.rejectWithValue({
          error: "Email supllied not recognised",
        });
      }
    } catch (err) {
      toast.error("Unable to send password reset notification", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);



export const reset_password_confirm = createAsyncThunk(
  "auth/users/reset_password_confirm",
  async ({ uid, token, new_password, re_new_password }, thunkAPI) => {
    const body = JSON.stringify({
      uid,
      token,
      new_password,
      re_new_password,
    });
    try {
      toast.info("New password submitted", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/users/reset_password_confirm/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });
      if (res.status === 200) {
        toast.success("Password succesfully updated", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return thunkAPI.fulfillWithValue({
          success: "Password Reset Email sent",
        });
      } else {
        return thunkAPI.rejectWithValue({
          error: "Email supllied not recognised",
        });
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const verifyToken = createAsyncThunk(
  "users/verifyToken",
  async (_, thunkAPI) => {
    const localStorageTokens = localStorage.getItem("authTokens");
    const sessionStorageTokens = sessionStorage.getItem("authTokens");
    const authTokens = JSON.parse(localStorageTokens || sessionStorageTokens || "{}");
  
    if (!authTokens.access) {
      return thunkAPI.rejectWithValue("No access token found");
    }

    const body = JSON.stringify({
      token: authTokens.access,
    });

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/token/verify/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      const data = await res.json();

      if (res.status === 200) {
        return { isAuthenticated: true };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "users/refreshToken",
  async (remember_me, thunkAPI) => {
    const localStorageTokens = localStorage.getItem("authTokens");
    const sessionStorageTokens = sessionStorage.getItem("authTokens");
    const authTokens = JSON.parse(localStorageTokens || sessionStorageTokens || "{}");
    if (!authTokens.refresh) {
      return thunkAPI.rejectWithValue("No refresh token found");
    }

    const body = JSON.stringify({
      refresh: authTokens.refresh,
    });

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      const data = await res.json();

      if (res.status === 200) {
        if (remember_me) {
          localStorage.setItem("authTokens", JSON.stringify(data));
        } else {
          sessionStorage.setItem("authTokens", JSON.stringify(data));
        }


        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk("users/logout", async (_, thunkAPI) => {
  // const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");

  // if (!authTokens.access) {
  //   return thunkAPI.rejectWithValue("No access token found");
  // }

  // const body = JSON.stringify({
  //   token: authTokens.access,
  // });

  try {
    // const res = await fetch("auth/token/logout", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body,
    // });

    // if (res.status !== 204) {
    //   return thunkAPI.rejectWithValue("Failed to invalidate token");
    // }

    // Clear local storage
    localStorage.removeItem("authTokens");
    sessionStorage.removeItem("authTokens");

    return { isAuthenticated: false };
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  registered: false,
  reportSectionLoading: false,
  reportSections: null,

  subscriptions: null,
  subscriptionsLoading: false,
  tickets: null,
  ticketsLoading: true,
  singleTicketLoading: true,
  singleTicket: [],

  resetPasswordLoading: false,
  resetPasswordConfirmLoading: false,
  tokenUpdateLoading: false,
  tokenVerifyLoading: false,
  rememberMe: false
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetRegistered: (state) => {
      state.registered = false;
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state) => {
        state.loading = false;
        state.registered = true;
      })
      .addCase(register.rejected, (state) => {
        state.loading = false;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.loading = false;
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state) => {
        state.loading = false;
      })

      .addCase(refreshToken.pending, (state) => {
        state.tokenUpdateLoading = true;
      })
      .addCase(refreshToken.fulfilled, (state) => {
        state.tokenUpdateLoading = false;
      })
      .addCase(refreshToken.rejected, (state) => {
        state.tokenUpdateLoading = false;
        state.isAuthenticated = false;
      })

      .addCase(reset_password.pending, (state) => {
        state.resetPasswordLoading = true;
      })
      .addCase(reset_password.fulfilled, (state) => {
        state.resetPasswordLoading = false;
      })
      .addCase(reset_password.rejected, (state) => {
        state.resetPasswordLoading = false;
      })

      .addCase(reset_password_confirm.pending, (state) => {
        state.resetPasswordConfirmLoading = true;
      })
      .addCase(reset_password_confirm.fulfilled, (state) => {
        state.resetPasswordConfirmLoading = false;
      })
      .addCase(reset_password_confirm.rejected, (state) => {
        state.resetPasswordConfirmLoading = false;
      })

      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(verifyToken.pending, (state) => {
        state.tokenVerifyLoading = true;
      })
      .addCase(verifyToken.fulfilled, (state) => {
        state.tokenVerifyLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(verifyToken.rejected, (state) => {
        state.tokenVerifyLoading = false;
        state.isAuthenticated = false;
      })
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(logout.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUserReportSection.pending, (state) => {
        state.reportSectionLoading = true;
      })
      .addCase(getUserReportSection.fulfilled, (state, action) => {
        state.reportSectionLoading = false;
        state.reportSections = action.payload;
      })
      .addCase(getUserReportSection.rejected, (state) => {
        state.reportSectionLoading = false;
      })

      .addCase(getUserSubscriptions.pending, (state) => {
        state.subscriptionsLoading = true;
      })
      .addCase(getUserSubscriptions.fulfilled, (state, action) => {
        state.subscriptionsLoading = false;
        state.subscriptions = action.payload;
      })
      .addCase(getUserSubscriptions.rejected, (state) => {
        state.subscriptionsLoading = false;
      })
      .addCase(getUserTickets.pending, (state) => {
        state.ticketsLoading = true;
      })
      .addCase(getUserTickets.fulfilled, (state, action) => {
        state.ticketsLoading = false;
        state.tickets = action.payload;
      })
      .addCase(getUserTickets.rejected, (state) => {
        state.ticketsLoading = false;
      })

      .addCase(getUserTicketSingle.pending, (state) => {
        state.singleTicketLoading = true;
      })
      .addCase(getUserTicketSingle.fulfilled, (state, action) => {
        state.singleTicketLoading = false;
        state.singleTicket = [...state.singleTicket, action.payload];
      })
      .addCase(getUserTicketSingle.rejected, (state) => {
        state.singleTicketLoading = false;
      });
  },
});

export const { resetRegistered, resetUserState, setRememberMe } = userSlice.actions;
export default userSlice.reducer;
