import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { formatRelativeTime } from "hubl/utils/helpers";

export default function CommentCardComponent({ date_created, content, author_full_name }) {
  return (
    <ListItem alignItems="flex-start">
    <ListItemAvatar>
      <Avatar alt={author_full_name} />
    </ListItemAvatar>
    <ListItemText
      primary={
        <>
          <Typography sx={{ display: "inline", fontWeight: "bold", backgroundColor: "3B3B42" }}>
            {author_full_name}
          </Typography>
          <Typography
            sx={{ display: "inline", fontSize: "11px", color: "7B7B7B", opacity: 1, pl: 1 }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {formatRelativeTime(date_created)}
          </Typography>
        </>
      }
      secondary={
        <Typography sx={{ display: "inline" }}>
          {content}
        </Typography>
      }
    />
  </ListItem>
  );
}
