import React from "react";
import Grid from "@mui/material/Grid";
import { formatDate } from "../../utils/helpers";

const TicketDetailComponent = ({
  Department_Name,
  Log_or_Ticket_Status,
  Log_or_Ticket_Number,
  Date_Created,
  Subject,
  Classification,
  Due_Date,
  Taxonomy,
}) => {
  let dateCreatedFormat = formatDate(Date_Created);
  let dateDueFormat = formatDate(Due_Date);

  return (
    <Grid container spacing={2} className="align-items-top ticket-details">
      <Grid item xs={12} md={3} lg="auto">
        <Grid container spacing={2} className="align-items-top">
          <Grid item md={12} lg="auto">
            <div className="detail-label">Date Created</div>
            <div className="detail-block">{dateCreatedFormat}</div>
          </Grid>
          <Grid item md={12} lg="auto">
            <div className="detail-label">Due Date</div>
            <div className="detail-block">{dateDueFormat}</div>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="align-items-top">
          <Grid item xs={12}>
            <div className="detail-label">Log/Ticket Status</div>
            <div className="detail-block">{Log_or_Ticket_Status}</div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} lg="auto">
        <Grid container spacing={2} className="align-items-top">
          <Grid item xs={12}>
            <div className="detail-label">Log/Ticket Number</div>
            <div className="detail-block">#{Log_or_Ticket_Number}</div>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="align-items-top">
          <Grid item xs={12}>
            <div className="detail-label">Classification</div>
            <div className="detail-block">
              {Classification ? Classification : "N/A"}
            </div>
          </Grid>{" "}
        </Grid>{" "}
      </Grid>
      <Grid item xs={12} md={3} lg="auto">
        <Grid container spacing={2} className="align-items-top">
          <Grid item md={12} lg="auto">
            <div className="detail-label">Department Name</div>
            <div className="detail-block">
              {Department_Name ? Department_Name : "N/A"}
            </div>
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2} className="align-items-top">
          <Grid item xs={12}>
            <div className="detail-label">Taxonomy</div>
            <div className="detail-block">{Taxonomy ? Taxonomy : "N/A"}</div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={3} lg="auto">
        <Grid container spacing={2} className="align-items-top">
          <Grid item xs={12}>
            <div className="detail-label">Subject</div>
            <div className="detail-block block-subject">{Subject}</div>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};

export default TicketDetailComponent;
