import { createSlice } from "@reduxjs/toolkit";

const selectDarkModeSlice = createSlice({
  name: "darkModeSlice",
  initialState: false,
  reducers: {
    setDark: (state, action) => {
      switch (action.payload) {
        case "LIGHT": {
          return false;
        }
        case "DARK": {
          return  true;
        }
        default:
          return  state.darkMode;
      }
    },
  },
});

export const { setDark } = selectDarkModeSlice.actions;
export default selectDarkModeSlice.reducer;
