import ContentLoader from 'react-content-loader';
import "./loader.scss"
const BarGraphLoader = ({height}) => (
    <div className="graph-container">
  <ContentLoader
    height={260}
    speed={1}
    backgroundColor={'lightgray'}
    foregroundColor={'#999'}
    viewBox="0 0 400 260"
  >
    {/* Y-axis */}
    <line x1="30" y1="50" x2="30" y2="220" stroke="#999" strokeWidth="2" />
    {/* X-axis */}
    <line x1="30" y1="220" x2="340" y2="220" stroke="#999" strokeWidth="2" />

    {/* Vertical Bar graph representation */}
    <rect x="40" y="120" rx="4" ry="4" width="20" height="100" />
    <rect x="90" y="90" rx="4" ry="4" width="20" height="130" />
    <rect x="140" y="70" rx="4" ry="4" width="20" height="150" />
    <rect x="190" y="140" rx="4" ry="4" width="20" height="80" />
    <rect x="240" y="100" rx="4" ry="4" width="20" height="120" />
    <rect x="290" y="150" rx="4" ry="4" width="20" height="70" />

  </ContentLoader></div>
);



export default BarGraphLoader;