import ContentLoader from 'react-content-loader';
import './loader.scss'
const KPILoader = () => (
    <div className="kpi-loader-container">
  <ContentLoader
    height={60}
    speed={1}
    backgroundColor={'lightgray'}
    foregroundColor={'#999'}
    viewBox="0 0 200 60"
  >
    {/* KPI Number Placeholder */}
    <rect x="50" y="10" rx="5" ry="5" width="100" height="40" />

  </ContentLoader></div>
);

export default KPILoader;