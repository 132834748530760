import React from "react";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Modal from "@mui/material/Modal";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const style = {
  padding: "40px",
  borderRadius: "10px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

export default function Popup({ open, onClose, content, header, image }) {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <Box>
            <CardMedia l={4} component="img" image={image} />
          </Box>
          <CardContent>
            <Typography variant="body2" color="text.secondary" textAlign="center">
              {content}
            </Typography>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}
