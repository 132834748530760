import { createSlice } from '@reduxjs/toolkit'

const toggleSidebarSlice = createSlice({
  name: 'sidebarToggle',
  initialState: {
    isToggled: true
},
  reducers: {
      toggleSidebar: (state, action) => {
        state.isToggled = action.payload
      },
    },
  })

export const { toggleSidebar } = toggleSidebarSlice.actions
export default toggleSidebarSlice.reducer

