import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

function SwitchButtonGroup({ toggles, value, onChange, colors }) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={onChange}
      aria-label="switch-button-group"
    >
      {toggles.map((toggle) => (
        <ToggleButton
          key={toggle.value}
          value={toggle.value}
          aria-label={toggle.label}
          color="primary"
          sx={{
            "&.Mui-selected": { backgroundColor: colors.primary[100], color: colors.primary[900] },
            "&.Mui-selected:hover": {
              backgroundColor: colors.primary[100],
              color: colors.primary[900],
            },
          }}
        >
          {toggle.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default SwitchButtonGroup;
