import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { makeAuthenticatedApiCall } from "hubl/utils/helpers";

export const fetchTicketComments = createAsyncThunk(
  "comments/history",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "comments/history/",
        "POST",
        body,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.error("Failed to fetch comments", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createComment = createAsyncThunk(
  "comments/create",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "comments/create/",
        "POST",
        body,
        thunkAPI
      )
      const data = await res.json();
      if (res.status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.error("Failed to fetch comments", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Slice
const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    ticketComments: [],
    ticketCommentsLoading: false,
  },
  reducers: {
    addComment: (state, action) => {
      return {
        ...state,
        ticketComments: [...state.ticketComments, action.payload]
      };
    }},
    
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicketComments.fulfilled, (state, action) => {
        state.ticketComments = action.payload;
        state.ticketCommentsLoading = false;
      })
      .addCase(fetchTicketComments.rejected, (state) => {
        state.ticketCommentsLoading = false;
      })
      .addCase(fetchTicketComments.pending, (state) => {
        state.ticketCommentsLoading = true;
      })
  },
});

export const { addComment } = commentsSlice.actions;
export default commentsSlice.reducer;
