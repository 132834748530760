import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const loading = false;
  const isAuthenticated = true;
  if (isAuthenticated && !loading) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
