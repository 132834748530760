import { createSlice } from '@reduxjs/toolkit'

const selectMenuSecionSlice = createSlice({
  name: 'selectedMenuItem',
  initialState: {section: ""},
  reducers: {
      selectMenuItem: (state, action) => {
        state.section = action.payload
      },
    },
  })

export const { selectMenuItem } = selectMenuSecionSlice.actions
export default selectMenuSecionSlice.reducer

