import React from "react";
import Backdrop from "@mui/material/Backdrop";
import LoadingGif from "assets/Loading.gif";

export default function BackdropComponent({ open }) {
  return (
    <>
      <Backdrop
        sx={{
          bgcolor: "black",
          color: "black",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <div className="d-flex align-items-center justify-content-center custom-height">
          <img height="200px" src={LoadingGif} alt="loading..." />
        </div>
      </Backdrop>
    </>
  );
}
