import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CommentListComponent from "./CommentList";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography"; // Ensure this is imported too
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { addComment } from "slice/commentSlice";
import { createComment } from "slice/commentSlice";
import { fetchSpecificUser } from "slice/user";

const CommentSectionComponent = ({ commentId }) => {
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [socket, setSocket] = useState(null);
  const { user } = useSelector((state) => state.userInfo);
  const { ticketComments } = useSelector((state) => state.comments);
  const ticketCommentsSorted = [...ticketComments].sort(
    (a, b) => new Date(b.date_created) - new Date(a.date_created)
  );

  const dispatch = useDispatch();

  const handleToggleCommentBox = () => {
    setShowCommentBox(!showCommentBox);
    const socket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET}/comments/${commentId}/`
    );

    socket.onopen = () => console.log("WebSocket connection opened.");
    socket.onclose = () => console.log("WebSocket connection closed.");
    socket.onerror = (event) => {
      console.log("WebSocket connection error:", event);
    };
    setSocket(socket);

    socket.onmessage = (event) => {
      const comment = JSON.parse(event.data);
      dispatch(fetchSpecificUser({ user_id: comment.user_id })).then((res) => {
        comment.author_full_name = res.payload.full_name;
        dispatch(addComment(comment));
      });
    };

    // Clean up the WebSocket connection when the component is unmounted
    return () => {
      if (socket) {
        console.log("WebSocket connection closed.");
        socket.close();
      }
    };
  };

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();

    dispatch(
      createComment({
        content: commentText,
        comment_id: `ticket_${commentId}`,
        user_id: user.id,
      })
    );

    if (commentText.trim()) {
      // Check if the WebSocket is in a state to send messages
      socket.send(
        JSON.stringify({
          message: commentText,
          user_id: user.id,
          comment_id: `ticket_${commentId}`,
        })
      );
    }

    setCommentText(""); // Clear the comment field after sending
  };

  // useEffect(() => {
  //   console.log("Comment component mounted");
  // }, []);

  return (
    <div style={{ paddingTop: "20px" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={"auto"} sm={"auto"}>
          <Button
            type="submit"
            variant="filled"
            color="primary"
            sx={{
              color: "#ffffff",
              borderRadius: "8px",
              opacity: "1",
              backgroundColor: "#ec5210",
              fontSize: "10px",
              letterSpacing: "1px",
              //   width: "111px",
              //   height: "23px",
              m: 0,
              pr: 1,
              pl: 1,
            }}
            onClick={handleToggleCommentBox}
          >
            <AddIcon /> ADD NOTE
          </Button>
        </Grid>

        <Grid item xs={"auto"} sm={"auto"}>
          <Typography
            sx={{ fontWeight: "bold", flexShrink: 0, color: "#9B9B9B" }}
          >
            (internal notes: {ticketComments ? ticketComments.length : 0})
          </Typography>
        </Grid>

        <Grid item xs={"auto"} sm={"auto"}>
          {showCommentBox ? (
            <ExpandMoreIcon
              onClick={handleToggleCommentBox}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <ExpandLessIcon
              onClick={handleToggleCommentBox}
              style={{ cursor: "pointer" }}
            />
          )}
        </Grid>
      </Grid>

      {showCommentBox && (
        <div style={{ paddingTop: "20px" }}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                multiline
                fullWidth
                placeholder="Please add internal notes here"
                rows={4}
                onChange={handleCommentChange}
                value={commentText}
              />
            </Grid>
            <Grid item container justifyContent="flex-end" xs={12} sm={12}>
              <Button
                type="submit"
                variant="filled"
                color="primary"
                sx={{
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  opacity: "1",
                  backgroundColor: "#D0CBC0",
                  fontSize: "10px",
                  letterSpacing: "1px",
                  width: "50px",
                  height: "23px",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="filled"
                color="primary"
                sx={{
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  opacity: "1",
                  backgroundColor: "#EAB238",
                  fontSize: "10px",
                  letterSpacing: "1px",
                  width: "80px",
                  height: "23px",
                  marginLeft: "10px",
                }}
                onClick={handleCommentSubmit}
              >
                Submit
              </Button>
            </Grid>
            <>
              {ticketComments ? (
                <Grid item xs={12} sm={12}>
                  <CommentListComponent comments={ticketCommentsSorted} />
                </Grid>
              ) : (
                <></>
              )}
            </>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default CommentSectionComponent;
