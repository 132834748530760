import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Grid from "@mui/material/Grid";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography
          variant="h3"
          color={colors.grey[300]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
        >
          {title}
        </Typography>
      </Grid>
      {subtitle && (
        <Grid item xs={12} sm={12}         sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          height: "100%",
          paddingTop:"0px !important"
        }}>
          <Typography
            variant="subtitle1" // You might want to adjust the variant depending on your design
            color={colors.grey[200]} // Adjust the color as per your design needs
          >
            {subtitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
