import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const postAnonymousContact = createAsyncThunk(
  "/contact/atom/anonymous",
  async ({ contact_name, contact_email, contact_phone, contact_message,  contact_solution, contact_origin_page}, thunkAPI) => {
    const body = JSON.stringify({ contact_name, contact_email, contact_phone, contact_message,  contact_solution, contact_origin_page });

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/contact/atom/anonymous/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      const data = await res.json();
      if (res.status === 201) {
        toast.info("Thank you! Message sent to Atoms DTS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);


export const putRegisterContact = createAsyncThunk(
  "/contact/atom/register",
  async (contact_dict, thunkAPI) => {
		const body = JSON.stringify(contact_dict);
		
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/contact/atom/register/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      const data = await res.json();
      if (res.status === 201) {
        toast.success("Register profile emails sent", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return data;
      } else {
        toast.error("Unable to send register profile emails", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);


const initialState = {
  contactBeingMade: false,
  registerEmailSending: false
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(postAnonymousContact.pending, (state) => {
        state.contactBeingMade = true;
      })
      .addCase(postAnonymousContact.fulfilled, (state, action) => {
        state.contactBeingMade = false;
      })
      .addCase(postAnonymousContact.rejected, (state) => {
        state.contactBeingMade = false;
      })
      .addCase(putRegisterContact.pending, (state) => {
        state.registerEmailSending = true;
      })
      .addCase(putRegisterContact.fulfilled, (state, action) => {
        state.registerEmailSending = false;
      })
      .addCase(putRegisterContact.rejected, (state) => {
        state.registerEmailSending = false;
      });
  },
});

export default contactSlice.reducer;
