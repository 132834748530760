import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { makeAuthenticatedApiCall } from "hubl/utils/helpers";

// Async thunk for fetching unread notifications
export const fetchUnreadNotifications = createAsyncThunk(
  "notifications/fetchUnread",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "notification/unread/list",
        "GET",
        null,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.error("Failed to fetch unread notifications:", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchAllNotifications = createAsyncThunk(
  "notifications/fetchAll",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "notification/all/list/",
        "POST",
        body,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.error("Failed to fetch unread notifications:", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Async thunk for marking notifications as read
export const markAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async ({ notificationId, userId }, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        `notification/markasread/${notificationId}/`,
        "POST",
        null,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return { notificationId, userId };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
     
    } catch (error) {
      console.error("Failed to mark notification as read", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Slice
const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    unreadNotifications: [],
    allNotifications: [],
    unreadCount: 0,
  },
  reducers: {
    // New reducer to set the number of unread notifications
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload;
    },
    addNotification: (state, action) => {
      state.allNotifications.push(action.payload);
      // Increment the unread count
      state.unreadCount += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnreadNotifications.fulfilled, (state, action) => {
        state.unreadNotifications = action.payload;
        state.unreadCount = action.payload.length;
      })
      .addCase(fetchAllNotifications.fulfilled, (state, action) => {
        state.allNotifications = action.payload;
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        const { notificationId, userId } = action.payload;
        // Find the index of the notification you are interested in
        const index = state.allNotifications.findIndex(
          (notification) => notification.id === notificationId
        );

        // If found, modify the read list of this notification
        if (index !== -1) {
          const readList = state.allNotifications[index].read;

          // Only add the userId if it is not already in the read list
          if (!readList.includes(userId)) {
            readList.push(userId);
          }
        }
      });
  },
});

export const { setUnreadCount, addNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
