import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CommentCardComponent from "./CommentCard";

const CommentListComponent = ({ comments }) => {
  return (
    <List sx={{ bgcolor: "none" }}>
      {comments.map((comment, index) => (
        <React.Fragment key={index}>
          <CommentCardComponent
            author_full_name={comment.author_full_name}
            content={comment.content}
            date_created={comment.date_created}
          />
          {index < comments.length - 1 && (
            <Divider variant="inset" component="li" />
          )}
        </React.Fragment>
      ))}
    </List>
  );
};
export default CommentListComponent;
