import { useDispatch, useSelector } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { logReportSelect, selectReport, selectReportPage } from "slice/pbi";
import { setReportConfig } from "slice/reportConfig";
import { selectDashboardSection } from "slice/dashboardSection";
import { PBI_MENU } from "../../utils/types";

const ReportPageAccordian = () => {
  const dispatch = useDispatch();
  const { userReportList, reportPages, reportList, report_id,  page_name} = useSelector(
    (state) => state.pbiReducer
  );
  const { section } = useSelector((state) => state.dashboardSection);
  
  const onClickPage = (page, page_report) => {
    dispatch(selectDashboardSection(PBI_MENU));
    if (page_report !== report_id) {
      dispatch(selectReport(page_report));
      window.scrollTo({ top: 0, behavior: 'smooth' });

      dispatch(
        setReportConfig({
          embedUrl: reportList[page_report].embedUrl,
          pageName: page,
        })
      );
    } else {
      dispatch(
        setReportConfig({
          pageName: page,
        })
      );
    }
    dispatch(logReportSelect(page_report));
    dispatch(selectReportPage(page));
  };
  // && !reportPagesLoading
  if (userReportList && reportPages) {
    const userReportsSorted = [...userReportList].sort(
      (a, b) => a.relative_sort - b.relative_sort
    );
    // const report_obj = userReportList.reduce((item, filter) => {
    //   item[filter.name] = false;
    //   return item;
    // }, {});

    return (
      <div>
        {userReportsSorted.map(({ report_id: r_id, title }) => {
          //
          const pagesByReport = reportPages[r_id];

          if (pagesByReport) {
          const pagesByReportSorted = [...pagesByReport].sort(
            (a, b) => a.order - b.order
          );

          return (
            <Accordion
              disableGutters
              elevation={0}
              defaultExpanded={true}
              key={`Accordian_${r_id}`}
              className="section"
              sx={{
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="accordian-icon" />}
                key={r_id}
                className="title"
                sx={{
                  margin: 0,
                }}
                // onClick={() => onClickReport(report_id)}
              >
                {title.toUpperCase()}
              </AccordionSummary>
              <AccordionDetails className="details">
                {pagesByReportSorted.map(({ name, displayName }) => (
                  <li
                    className={`item ${
                      `${r_id}_${name}` === `${report_id}_${page_name}` &&
                      PBI_MENU === section
                        ? "item-active"
                        : ""
                    }`}
                    onClick={() => onClickPage(name, r_id)}
                    key={`${r_id}_${name}`}
                  >
                    <DashboardIcon className="icon" />
                    <span>
                      <div
                        className={`item ${
                          `${r_id}_${name}` === `${report_id}_${page_name}` &&
                          PBI_MENU === section
                            ? "list-item"
                            : ""
                        }`}
                        key={`${r_id}_${name}`}
                      >
                        {displayName}
                      </div>
                    </span>
                  </li>
                ))}
              </AccordionDetails>
            </Accordion>
          );

                      }
        })}
      </div>
    );
  } else {
    return <></>;
  }
};

export default ReportPageAccordian;
